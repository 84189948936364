<template>
  <div class="container toefl-list">
    <!-- <el-alert
      v-if="!isChromium()"
      :title="$t('toefl.tips_device_and_browser')"
      type="warning"
      show-icon
      center
      :closable="false"
      style="margin: 10px 0"
    >
    </el-alert> -->
    <ToeflMenu active="1" />
    <div class="toefl-header">
      <PageTitle>
        <div v-if="isNew === 2">
          {{ $t("toefl.index.title_new") }}
        </div>
        <div v-if="isNew === 1">
          {{ $t("toefl.index.title_old") }}
        </div>
      </PageTitle>
      <div class="change-version">
        <el-button
          v-if="isNew === 2"
          @click="changeVersion(1)"
          style="width:auto"
          ><i class="el-icon-refresh"></i>
          {{ $t("toefl.index.switch_old") }}</el-button
        >
        <el-button
          v-if="isNew === 1"
          @click="changeVersion(2)"
          style="width:auto"
          ><i class="el-icon-refresh"></i>
          {{ $t("toefl.index.switch_new") }}</el-button
        >
      </div>
    </div>
    <el-alert
        class="mt-2 mb-2"
        v-if="isNew == 1"
        type="info"
        show-icon
        :closable="false"
      >
        <b>
          {{ $t("toefl.switch_old_text") }}
          <el-button @click="changeVersion(2)" size="small" type="text"
            ><i class="el-icon-refresh"></i>
            {{ $t("toefl.index.switch_new") }}</el-button
          >
          {{ $t("toefl.switch_old_text2") }}
        </b>
      </el-alert>
      <el-alert
        class="mt-2 mb-2"
        v-if="isNew == 2"
        type="info"
        show-icon
        :closable="false"
      >
        <b>
          {{ $t("toefl.switch_old_text") }}
          <el-button @click="changeVersion(1)" size="small" type="text"
            ><i class="el-icon-refresh"></i>
            {{ $t("toefl.index.switch_old") }}</el-button
          >
          {{ $t("toefl.switch_old_text2") }}
        </b>
      </el-alert>
    <el-alert
      v-if="countryCode === null"
      show-icon
      type="warning"
      :closable="false"
      title="Try switching networks to unlock more features!"
    />
    <ToeflListTitle
      :testType="testType"
      :singleType.sync="singleType"
      :isNew="isNew"
    />
    <ToeflListBody
      :testType="testType"
      :isNew="isNew"
      :countryCode="countryCode"
    />
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import PageTitle from "@/components/PageTitle.vue";
import ToeflMenu from "@/views/toefl/components/ToeflMenu.vue";
import ToeflListTitle from "@/views/toefl/toeflList/ToeflListTitle";
import ToeflListBody from "@/views/toefl/toeflList/ToeflListBody";
import ToeflMixin from "@/mixins/toefl.js";
import TOEFL from "@/apis/toefl";


export default {
  metaInfo() {
    return {
      title: "Practices - " + this.CompanyName
    };
  },
  components: {
    PageTitle,
    ToeflMenu,
    ToeflListTitle,
    ToeflListBody
  },

  mixins: [ToeflMixin],

  props: [],
  data() {
    return {
      cardsTotal: 0,
      countryCode: undefined,
      isNew: null
    };
  },
  computed: {
    ...mapState("user", ["profile", "token"]),
    testType() {
      let testType = "reading";
      if (this.$route.query.testType === undefined) {
        testType = "reading";
      } else {
        testType = this.$route.query.testType;
      }
      return testType;
    },
    singleType() {
      if (this.$route.query.testType == "quick") {
        return this.$route.query.singleType;
      }
      return "";
    }
  },
  watch: {},

  async created() {
    if (this.$route.query.isNew === undefined) {
      this.isNew = parseInt(await this.getVersion());
    } else {
      this.isNew = parseInt(this.$route.query.isNew);
    }
    const { iso_code } = await this.$store.dispatch("ipGeo/getGeoLocation");
    this.countryCode = iso_code;
  },

  methods: {
    async getVersion() {
      const res = await TOEFL.getMetaData();
      if (res && res.version) {
        return res.version;
      } else {
        return 1;
      }
    },
    async changeVersion(val) {
      await TOEFL.postMetaData({
        metadata: {
          version: val
        }
      });
      this.routerPush({
        name: "LandingPage",
        query: {
          testType: this.testType,
          isNew: val
        }
      });
    }
  }
};
</script>
<style scoped>
.toefl-header {
  height: 50px;
  padding: 0 20px;
  border-radius: 5px;
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.toefl-list {
  margin-bottom: 10px;
}
.toefl-link {
  font-size: 14px;
}
@media screen and (max-width: 836px) {
  .toefl-header {
    display: block;
    width: 100%;
    height: auto;
    padding: 0;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 736px) {
  .toefl-link {
    margin-top: 10px;
    font-size: 14px;
    display: block;
  }
  .change-version {
    display: flex;
  }
  ::v-deep .change-version .el-button {
    width: auto;
  }
}
@media screen and (max-width: 400px) {
  ::v-deep .change-version .el-button {
    width: 100%;
  }
}
</style>
